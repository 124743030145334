.flexContainer {
  display: flex;
  gap: var(--xl);
  width: 100%;
  align-items: stretch;
  height: 100%;
}

.flexContainerChild1 {
  flex-grow: 1;
  width: 65%;
}

.flexContainerChild2 {
  flex-grow: 1;
  width: 35%;
}

.statusStack {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--md);
  width: 100%;
  height: 100%;
}

.infoCardFirstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: var(--md);
  width: 100%;
  gap: var(--sm);
}

.organizationSideStack {
  display: flex;
  flex-direction: column;
  gap: var(--xs);
}

.rescheduleButton {
  width: fit-content;
}

.organizationContactInfoStack {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--xl);
}

.productsGrid {
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(min(18em, 100%), 1fr));
  gap: var(--lg);
  display: none;
  visibility: hidden;
}

.productsTableContainer {
  visibility: visible;
}

@media (max-width: 768px) {
  .flexContainer {
    flex-direction: column-reverse;
  }
  .flexContainerChild1,
  .flexContainerChild2 {
    width: 100%;
  }
  .statusStack {
    flex-direction: row;
    justify-content: center;
  }
  .productsTableContainer {
    display: none;
    visibility: hidden;
  }
  .productsGrid {
    display: grid;
    visibility: visible;
  }
}

@media (max-width: 576px) {
  .infoCardFirstRow {
    flex-direction: column;
    align-items: flex-start;
  }
  .organizationSideStack {
    width: 100%;
  }
  .rescheduleButton {
    width: 100%;
  }
  .statusStack {
    flex-direction: column;
  }
  .organizationContactInfoStack {
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    align-items: flex-start;
  }
}

.productsTable {
  width: 100%;
  padding-top: var(--md);
  border-collapse: collapse;
  overflow: hidden;
  border-radius: 20px;
  color: var(--body);
}

.productsTable tr {
  border-top: 1px solid var(--divider40);
}

.productsTable tr:first-child {
  border: none;
}

.productsTable tr td {
  height: 72px;
}

.productsTable tr th {
  height: 56px;
  background-color: var(--divider40);
  color: var(--bodyLight);
  font-weight: 500;
}

.productsTable tr th:first-child,
td:first-child {
  padding-left: 24px;
}

.productsTable tr th:last-child,
td:last-child {
  padding-right: 24px;
}

.productsTable tr {
  vertical-align: middle;
  text-align: center;
}
