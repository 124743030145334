.navigationFloatingContainer {
    width: 100%;
    position: fixed;
    z-index: 100;
    /* min-height: 135px; */
    background-color: var(--bg);
    padding: 24px 0 24px 0;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .navLinks {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 45px;
    visibility: visible;
  }
  
  .navLinks a {
    font-size: 1rem;
    color: var(--heading);
  }
  
  @media (max-width: 768px) {
    .navLinks {
      display: none;
      visibility: hidden;
    }
  }
  
  .navIcons {
    display: flex;
    gap: 26px;
    align-items: center;
    visibility: visible;
  }
  
  @media (max-width: 768px) {
    .navIcons {
      display: none;
      visibility: hidden;
    }
  }
  
  /* .activePage {
    background-color: var(--accent);
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 500;
  } */
  
  .menuIcon {
    visibility: visible;
    display: block;
  }
  
  @media (min-width: 769px) {
    .menuIcon {
      display: none;
      visibility: hidden;
    }
  }
  
  @media (max-width: 769px) {
    .ctaButton {
      display: none;
      visibility: hidden;
    }
  }
  
  .cookieBannerFlex {
    position: fixed;
    display: flex;
    background-color: var(--elevation);
    bottom: 24px;
    justify-content: space-between;
    gap: var(--md);
    align-items: center;
    padding: var(--md);
    border-radius: 20px;
    box-shadow: var(--sh);
    z-index: 100;
    max-width: 1192px;
    width: calc(100% - 32px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid var(--divider20);
  }
  
  .cookieBannerFlex div {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .sidebarList {
    list-style: none;
  }
  
  .sidebarElement {
    padding: 12px 0;
    width: 100%;
  }
  
  .footerFlexContainer {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .footerFlexContainer {
      flex-direction: column;
      gap: var(--lg);
      justify-content: flex-start;
    }
  }
  
  .footerInfoContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  @media (max-width: 576px) {
    .footerInfoContainer {
      flex-direction: column;
      gap: var(--lg);
    }
  
    .cookieBannerFlex {
      flex-direction: column;
      align-items: center;
    }
  
    .cookieBannerFlex div {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  