:root {
  /* DIMENSIONS */
  --h1: 70px;
  --h2: 48px;
  --h3: 32px;
  --h4: 24px;
  --buttonText: 18px;
  --p: 16px;
  --caption: 14px;

  /* DISTANCES */
  --sections-gap: 160px;
  --small-sections-gap: 100px;
  --nav-margin: 120px;
  --max-p-span: 600px;
  --max-hero-span: 900px;
  --feature-p-span: 600px;
  --xs: 8px;
  --sm: 16px;
  --md: 24px;
  --lg: 32px;
  --xl: 40px;
  /* breakpoints: 950, 768, 576, 350 */

  /* EFFECTS */
  --tr: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

:root[data-theme="light"] {
  --accent: #e9a708;
  --accent-gr: linear-gradient(
    10deg,
    rgba(233, 167, 8, 1) 0%,
    rgba(250, 221, 149, 1) 100%
  );
  --accent80: rgb(233, 167, 8, 0.8);
  --accent20: rgb(233, 167, 8, 0.2);
  --accent10: rgb(233, 167, 8, 0.1);
  --bg: #fafafa;
  --bg10: rgba(250, 250, 250, 0.1);
  --elevation: #ffffff;
  --negative-elevation: #f6f7f9;
  --heading: #0f222d;
  --body: #333333;
  --divider: #dcdfe4;
  --divider40: rgba(220, 223, 228, 0.4);
  --divider20: rgba(220, 223, 228, 0.2);
  --grey: #adb5bd;
  --bodyLight: #667085;
  --green: #2BA12F;
  --blend-in-gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 20%,
    var(--bg) 90%
  );
  --sh: 0px 0px 0px 0px rgba(0, 0, 0, 0.06),
    0px 5px 22px 0px rgba(0, 0, 0, 0.04);
  --sh-accent: 0px 1px 85px 1px var(--accent20);
  --inset-sh: inset 0px -4px 12px -4px rgba(0, 0, 0, 0.25);
  --footer-inset-sh: inset 0px 4px 12px -4px rgba(0, 0, 0, 0.25);
}

:root[data-theme="dark"] {
  --accent: #c09631;
  --accent-gr: linear-gradient(
    10deg,
    rgba(233, 167, 8, 1) 0%,
    rgba(250, 221, 149, 1) 100%
  );
  --heading-gr: linear-gradient(10deg, #0f222d 0%, #1d4258 100%);
  --accent80: #bb8a0b;
  --accent20: rgba(192, 150, 49, 0.2);
  --accent10: rgba(192, 150, 49, 0.1);
  --bg: #05141b;
  --elevation: #11202d;
  --negative-elevation: #0d1821;
  --heading: #eff1f1;
  --body: #c8ced0;
  --divider: #333942;
  --divider40: rgba(51, 57, 66, 0.4);
  --divider20: rgba(51, 57, 66, 0.2);
  --grey: #adb5bd;
  --dark-mode-sh: rgba(41, 41, 41, 0.2);
  --bg10: rgba(250, 250, 250, 0.1);
  --blend-in-gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 20%,
    var(--bg) 90%
  );
  --bodyLight: #667085;
  --green: #34c439;
  --sh: 0px 0px 0px 0px var(--dark-mode-sh),
    0px 5px 22px 0px rgba(0, 0, 0, 0.04);
  --inset-sh: inset 0px -4px 12px -4px var(--dark-mode-sh);
  --footer-inset-sh: inset 0px 4px 12px -4px var(--dark-mode-sh);
  --sh-accent: 0px 1px 85px 1px var(--accent10);
}

@media (max-width: 950px) {
  :root {
    --feature-p-span: 100%;
  }
}

@media (max-width: 768px) {
  :root {
    --h1: 42px;
    --h2: 32px;
    --h3: 24px;
    --h4: 20px;
    --sections-gap: 80px;
    --small-sections-gap: 80px;
    --max-p-span: 100%;
    --max-hero-span: 100%;
    --nav-margin: 100px;
  }
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
}

.margin-container {
  max-width: 1192px;
  margin: 0 auto;
}

.padding-container {
  max-width: 1720px;
  padding: 0 auto;
}

@media (max-width: 1250px) {
  .margin-container {
    margin: 0 auto;
    padding: 0 32px;
  }

  .padding-container {
    padding: 0 auto;
    padding: 0 32px;
  }
}

@media (max-width: 768px) {
  .margin-container {
    margin: 0 auto;
    padding: 0 16px;
  }

  .padding-container {
    padding: 0 auto;
    padding: 0 16px;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

body {
  color: var(--heading);
  background: var(--bg);
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* TYPOGRAPHY */
.p {
  font-size: var(--p);
  font-weight: 400;
  color: var(--body);
  line-height: 150%;
  max-width: var(--max-p-span);
}

.bt {
  font-size: 18px;
  font-weight: 600;
  color: var(--heading);
  line-height: 150%;
}

.link {
  font-size: var(--p);
  font-weight: 400;
  color: var(--heading);
  line-height: 150%;
  max-width: var(--max-p-span);
  transition: var(--tr);
}

.link:hover {
  text-decoration: underline;
}

.h1 {
  font-size: var(--h1);
  font-weight: 700;
  color: var(--heading);
  line-height: 100%;
}

.h2 {
  font-size: var(--h2);
  font-weight: 700;
  color: var(--heading);
  line-height: 120%;
}

.h3 {
  font-size: var(--h3);
  font-weight: 600;
  color: var(--heading);
  line-height: 120%;
}

.h4 {
  font-size: var(--h4);
  font-weight: 600;
  color: var(--heading);
  line-height: 120%;
}

.caption {
  font-size: var(--caption);
  font-weight: 400;
  color: var(--body);
  line-height: 150%;
}

.eyebrow {
  font-size: var(--caption);
  font-weight: 600;
  color: var(--bodyLight);
  line-height: 150%;
  text-transform: uppercase;
}

/* BUTTONS */

.btn-pr {
  width: fit-content;
  padding: 12px 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  /* transition: background 0.2s, color 0.2s, border-color 0.2s; */
  border: none;
  border-radius: 48px;
  background: var(--accent-gr);
  font-size: var(--buttonText);
  font-weight: 600;
  line-height: 150%;
  color: var(--elevation);
  transition: var(--tr);

  overflow: hidden;
  position: relative;

  &:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 2;
  }
}

.static-btn-pr {
  width: fit-content;
  padding: 12px 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border: none;
  border-radius: 48px;
  background: var(--accent-gr);
  font-size: var(--buttonText);
  font-weight: 600;
  line-height: 150%;
  color: var(--elevation);
  transition: var(--tr);
  height: 100%;
}

.btn-pr:hover {
  &:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  scale: 1.05;
}

.btn-sc {
  width: fit-content;
  justify-content: center;
  padding: 12px 32px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  transition: var(--tr);
  border: 1px solid var(--heading);
  border-radius: 48px;
  font-size: var(--buttonText);
  font-weight: 600;
  line-height: 150%;
  color: var(--heading);
}

.btn-sc:hover {
  background: var(--heading);
  color: var(--elevation);
  scale: 1.05;
}

.btn-tr {
  width: fit-content;
  padding: 12px 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border: none;
  background: none;
  border-radius: 48px;
  transition: background 0.2s, color 0.2s, border-color 0.2s;
  font-size: var(--buttonText);
  font-weight: 600;
  line-height: 150%;
  color: var(--heading);
}

.context-btn {
  border-radius: 4px;
  border: 1px solid var(--divider);
  color: var(--heading);
  padding: var(--xs);
  width: fit-content;
  background-color: transparent;
  cursor: pointer;
  font-size: var(--p);
  font-weight: 400;
  line-height: 150%;
  color: var(--heading);
}

.context-btn:hover {
  background-color: var(--divider20);
}

.buttonsGroup {
  display: flex;
  gap: var(--sm);
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: 576px) {
  .buttonsGroup {
    flex-direction: column;
  }

  .buttonsGroup button {
    width: 100%;
  }
}

/* OTHERS */
.input-style {
  min-height: 48px;
  border-radius: var(--xs);
  background: none;
  width: 100%;
  border-color: var(--divider);
  color: var(--body);
}

.search-input-style {
  min-height: 48px;
  border-radius: 1000px;
  background: none;
  width: 100%;
  border-color: var(--divider);
  color: var(--body);
}

.input-style::placeholder,
.search-input::placeholder {
  font-size: var(--p);
  color: var(--grey);
}

.textarea-style {
  border-radius: var(--xs);
  background: none;
  /* min-height: 144px; */
  width: 100%;
  border-color: var(--divider);
  padding: 12px;
  color: var(--body);
  font-size: var(--p);
}

.textarea-style::placeholder {
  font-size: var(--p);
  color: var(--grey);
}

.outline-ai {
  border-radius: 4px;
  border: 1px solid var(--divider);
  color: var(--heading);
  padding: var(--xs);
  width: 40px !important;
  height: 40px !important;
}

.outline-ai:hover {
  background-color: var(--divider20);
  color: var(--heading);
}

.menu-dropdown {
  background-color: var(--elevation);
  border: 1px solid var(--divider40);
  box-shadow: var(--sh);
  border-radius: 8px;
  padding: 0;
}

.menu-item {
  color: var(--heading);
  padding: 12px;
}

.menu-item:hover {
  background-color: var(--divider20);
}

/* .label {
  font-size: 14px;
  line-height: 21.7px;
  color: var(--heading);
  font-weight: 500;
} */

.terms-custom {
  font-size: 16;
  color: var(--body);
  line-height: 150%;
}

.terms-custom p {
  margin-top: px;
}
